<template>
  <div class="login">
    <v-container>
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-card
            max-width="600"
            width="600"
            class="pa-5"
          >
            <h3 class="text-h5">
              Log In
            </h3>

            <v-alert
              v-if="errors.non_field_errors"
              dense
              border="left"
              type="warning"
            >
              {{ errors.non_field_errors[0] }}
            </v-alert>

            <form @submit.prevent="submit">
              <v-text-field
                v-model="formFields.email"
                label="Email"
                type="email"
                :error-messages="errors.username"
                required
              />

              <v-text-field
                v-model="formFields.password"
                label="Password"
                :error-messages="errors.password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                required
                @click:append="showPassword = !showPassword"
              />

              <v-btn
                :to="{ name: 'password_reset' }"
                color="primary"
                plain
                :ripple="false"
                class="forgot-password-link pa-0"
              >
                Forgot password or need to set one up?
              </v-btn>

              <div class="text-right">
                <v-btn
                  type="submit"
                  color="primary"
                  :disabled="submitting"
                  class="mt-2"
                >
                  Log In
                  <v-progress-circular
                    v-if="submitting"
                    size="20"
                    width="2"
                    indeterminate
                    class="ml-2"
                  />
                </v-btn>
              </div>
            </form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'LoginView',
  data() {
    return {
      submitting: false,
      errors: {},
      formFields: {
        email: '',
        password: '',
      },
      showPassword: false,
    };
  },
  metaInfo: {
    title: 'Log In',
  },
  methods: {
    async submit() {
      this.errors = {};
      this.submitting = true;

      const postData = {
        username: this.formFields.email,
        password: this.formFields.password,
      };

      const responseData = await this.api({
        url: 'token-auth/',
        method: 'POST',
        json: postData,
      });

      this.submitting = false;

      if (responseData.status === 200) {
        this.$store.commit('userData', responseData.body);
        this.$router.push({ name: 'home' });
      } else if (responseData.status === 400) {
        this.errors = responseData.body;
      } else {
        this.errors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @media (max-width: 479.98px) {
    .forgot-password-link {
      display: inline-block;
      white-space: normal;
    }
  }
</style>
